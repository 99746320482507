import React, { Fragment, useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Formik, Form } from "formik";

import Layout from "layout";
import Container from "layout/Container";
import Section from "elements/Section";
import Message from "elements/Message";
import ActionButtons from "elements/ActionButtons";

import { generateFormField } from "elements/Form/services/form";
import { useSectionFormFields } from "../ConsultationContext/hooks/useSectionFormFields";

import { isBrowser, isObjectEmpty } from "services/general";
import { getSignedInUser } from "../../Auth/services/user";
import { generateInitialValues } from "../../../services/context";

import { ConsultationContext } from "../ConsultationContext/ConsultationContext";

const BookingIntake = ({ pageContext }) => {
	const [homepageUrl, setHomepageUrl] = useState("/services");
	const userData = getSignedInUser().userData;
	let coaches = (
		(isBrowser() && JSON.parse(sessionStorage.getItem("coaches"))) ||
		[]
	).map((coach) => {
		return {
			label: `${coach.firstName} ${coach.lastName}, ${coach.license}`,
			value: coach,
		};
	});

	const { consultationState, consultationDispatch } = useContext(
		ConsultationContext
	);

	let { progress, numberOfPages } = pageContext;

	let { sectionFormFields, validationSchema } = useSectionFormFields({
		formFields: !userData?.email.includes("@medgrocer.com")
			? pageContext.formFields.filter(
					(field) =>
						field.name !== "memberType" || !field?.name?.includes("dependent")
			  )
			: pageContext.formFields,
	});

	sectionFormFields = sectionFormFields.sort((a, b) => a.order - b.order);

	// Coach Options
	if (pageContext.module.name === "coaching") {
		let coachIndex = sectionFormFields[0].fields.findIndex(
			(field) => field.name === "coach"
		);

		sectionFormFields[0].fields[coachIndex].options = coaches;
	}

	const handleSubmit = async (values) => {
		await consultationDispatch({
			type: "SAVE_MEDICAL_HISTORY",
			payload: {
				...values,
			},
		});

		navigate(pageContext.nextPath);
	};

	useEffect(() => {
		if (isBrowser()) {
			consultationDispatch({ type: "GET_CONTEXT_FROM_SESSION" });

			setHomepageUrl(JSON.parse(sessionStorage.getItem("accessRule"))?.homeUrl);
		}
	}, [consultationDispatch]);

	if (consultationState?.channel === "") delete consultationState?.channel;

	let initialValues = {
		...generateInitialValues({
			fields: pageContext.formFields,
		}),
		...consultationState,
	};

	const handleFollowUpOnChange = ({
		field,
		setFieldValue,
		e,
		formFields,
		values,
	}) => {
		//resetField
		for (let i = 0; i < field.followUpQuestions.length; i++) {
			let followUpField = formFields.filter(
				(f) => f.name === field.followUpQuestionsName[i]
			)[0];

			switch (true) {
				case followUpField.type === "select":
					setFieldValue(field.followUpQuestionsName[i], {
						value: undefined,
						label: undefined,
					});
					break;
				case followUpField.type === "date":
					setFieldValue(field.followUpQuestionsName[i], {
						month: {
							value: undefined,
							label: undefined,
						},
						date: {
							value: undefined,
							label: undefined,
						},
						year: {
							value: undefined,
							label: undefined,
						},
					});
					break;
				default:
					setFieldValue(field.followUpQuestionsName[i], "");
			}
		}
		if (e.target.name === "channel" && e.target.value === "Mobile Phone Call")
			setFieldValue(
				"mobileNumber",
				consultationState.mobileNumber || userData.mobileNumber
			);
	};

	return (
		<Layout
			title={pageContext?.module?.title}
			subtitle={pageContext?.module?.subtitle}
			seoTitle={pageContext?.module?.seoTitle}
			pageContext={pageContext}
			isPrivate
			progress={(progress / numberOfPages) * 100}
			numberOfPages={numberOfPages}
			polygonStyle="forms"
			isForms
		>
			<Container isCentered>
				<Formik
					initialValues={{
						...initialValues,
						dependentBirthdayv2: {
							month: { label: "", value: "" },
							date: { label: "", value: "" },
							year: { label: "", value: "" },
						},
					}}
					onSubmit={handleSubmit}
					enableReinitialize={true}
					validationSchema={validationSchema}
				>
					{({ values, setFieldValue, errors }) => (
						<Form>
							{sectionFormFields.map((section) => {
								const lastIndex = section?.fields.length;
								return (
									<Section
										title={section?.section}
										subtitle={section?.subtitle || ""}
									>
										{section?.message && (
											<Message color="light">{section?.message}</Message>
										)}
										<ol className="ml-1">
											{section?.fields.map((field, index) => {
												if (!field?.referenceAnswer) {
													if (field?.followUpQuestions.length)
														field.onChange = (e) => {
															handleFollowUpOnChange({
																field,
																formFields: section?.fields,
																setFieldValue,
																e,
																values,
															});
														};

													return (
														<Fragment>
															<li className="">
																{generateFormField({
																	formFields: section?.fields,
																	formField: {
																		...field,
																		isHorizontal:
																			section.module === "Kessler 6" &&
																			index !== lastIndex,
																		isMedicalHistory: true,
																	},
																	values,
																	setFieldValue,
																	context: {
																		state: consultationState,
																		dispatch: consultationDispatch,
																	},
																})}
																{!!field?.addDividerAfterField && (
																	<hr className="has-background-light" />
																)}
															</li>
														</Fragment>
													);
												}
												return null;
											})}
										</ol>
									</Section>
								);
							})}

							{!isObjectEmpty(errors) && (
								<Message>
									You may have missed some required fields. Please scan through
									the form and check if your information is complete.
								</Message>
							)}

							<ActionButtons
								back={{
									link:
										pageContext.backPath === "/"
											? homepageUrl
											: pageContext?.backPath,
								}}
								submit={{ label: `Next: ${pageContext?.nextPathTitle}` }}
							/>
						</Form>
					)}
				</Formik>
			</Container>
		</Layout>
	);
};

export default BookingIntake;
